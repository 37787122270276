import React from 'react';
import { Typography } from 'amphitheatre';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';

import * as Base from 'src/page-styles/base.styled';

const { Header } = Typography;

const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <Layout>
      <Container.Dark>
        <Base.MenuGap />
        <Base.Heading>Terms and Conditions</Base.Heading>
        <Base.Text>
          <Base.Paragraph>
            Welcome to our website. This website with URL address
            http://saberastro.com is owned and operated by Saber Astronautics
            which refers to Saber Astronautics LLC and Saber Astronautics
            Australia Pty Ltd (‘Saber Astronautics’). The term ‘you’ or ‘your’
            refers to the website user. Should you continue to use this website,
            you are agreeing to comply with and be bound by the following terms
            and conditions of use, which together with our privacy policy govern
            Saber Astronautics’ relationship with you in connection with this
            website. Should you not agree with any of these terms and
            conditions, please do not use our website.
          </Base.Paragraph>

          <Header level={3}>
            Your use of this website is subject to the following terms and
            conditions:
          </Header>
          <Base.Paragraph>
            1. The content of this website is for your general information and
            use only. It is subject to change without prior notice.
          </Base.Paragraph>
          <Base.Paragraph>
            2. This website uses cookies to monitor browsing preferences. If you
            allow cookies to be used, the following personal information may be
            stored by us for use by third parties: N/A.
          </Base.Paragraph>
          <Base.Paragraph>
            3. Neither we nor any third parties provide any warranty or
            guarantee as to the performance, accuracy, timeliness, completeness
            or suitability of the information and materials found or offered on
            this website for any particular purpose. You hereby acknowledge that
            such information and materials may contain mistakes, inaccuracies or
            errors and we expressly exclude any liability for such to the
            fullest extent permissible by law.
          </Base.Paragraph>
          <Base.Paragraph>
            4. Your use of any information or materials on this website is
            entirely at your own risk, for which we shall not be liable. It
            shall be your own responsibility to ensure that any products,
            services or information available through this website meet your
            specific requirements.
          </Base.Paragraph>
          <Base.Paragraph>
            5. This website contains material which is owned by or licensed to
            us. This material includes, but is not limited to, the content,
            design, layout, appearance, look and graphics of the website. Any
            reproduction of the website’s material is prohibited other than in
            accordance with the copyright notice, which forms part of these
            terms and conditions.
          </Base.Paragraph>
          <Base.Paragraph>
            6. All trademarks reproduced in this website, which are not the
            property of, or licensed to us, are acknowledged on the website.
          </Base.Paragraph>
          <Base.Paragraph>
            7. Unauthorised use of this website may be a criminal offence and/or
            give rise to a claim for damages.
          </Base.Paragraph>
          <Base.Paragraph>
            8. This website may also, on occasion, include links to other
            websites which are not controlled by us. These links are provided
            for your convenience to provide you with further information. You
            acknowledge that they are used at your own risk. They do not signify
            that we recommend or endorse the websites. We have no control over
            the nature, content and availability of those websites.
          </Base.Paragraph>
          <Base.Paragraph>
            9. Your use of this website and any dispute arising out of your use
            of it is subject to the laws of New South Wales.
          </Base.Paragraph>
          <Base.Paragraph>
            10. You may only use the website for lawful purposes and in a manner
            consistent with the nature and purpose of the website.
          </Base.Paragraph>
          <Base.Paragraph>
            11. These terms and conditions do not relate to your use of any
            product or service described on our website unless otherwise agreed.
            You must refer to the individual warranty relevant to any particular
            product or service.
          </Base.Paragraph>
          <Base.Paragraph>
            12. These terms and conditions may be amended from time to time.
            Your continued use of our website following any such amendments will
            be deemed to be confirmation that you accept those amendments.
          </Base.Paragraph>
          <Base.Paragraph>
            13. You indemnify us from and against all claims, suits, demands,
            actions, liabilities, costs and expenses (including legal costs and
            expenses on a full indemnity basis) resulting from your use of the
            website.
          </Base.Paragraph>
          <Base.Paragraph>
            14. Every effort is made to keep the website up and running
            smoothly. However, we take no responsibility for, and will not be
            liable for, the website being temporarily unavailable due to
            technical issues beyond our control.
          </Base.Paragraph>
        </Base.Text>
      </Container.Dark>
    </Layout>
  );
};

export default PrivacyPolicy;
